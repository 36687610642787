@import 'styles/colors.scss';

.admin {
  .content {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-wrap: wrap;
    margin: 0;
    padding: 10px;

    .admin-link {
      align-items: center;
      display: flex;
      flex-direction: row;
      font-size: 36px;
      height: 125px;
      justify-content: center;
      margin-bottom: 10px;
      text-decoration: none;
      width: 400px;
    }
  }
}
