.left-rail {
  width: 200px;
  height: 100%;
  border-right: 1px solid darkgray;
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    font-weight: normal;
    margin: 0;
    height: 40px;
    padding: 0;

    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 5px;
  }

  &-header {
    border-bottom: 1px solid darkgray;
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
    width: 100%;
    padding-left: 10px;
  }

  .substation {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .label-container {
    width: 100%;
    display: flex;
    height: 40px;
    align-items: center;
    padding-left: 10px;
  }

  .feeder {
    width: 100%;
    padding-left: 20px;
  }
}
