.feeder-settings {
  .column-container {
    display: flex;
  }

  .column {
    display: flex;
    flex-direction: column;
  }
}
