@import 'styles/colors.scss';

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  background: radial-gradient($white 1px, transparent 13%) 0 0,
    radial-gradient($white 1px, transparent 13%) 22px 22px;
  background-color: #ededed;
  background-size: 22px 22px;
  color: #262626;

  .center-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px;
    width: 320px;
    border-radius: 8px;
    padding: 10px 31px;
    flex-direction: column;

    .content {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 0;
    }
  }

  .logo-container {
    height: 80px;
    width: 100%;
    flex-shrink: 0;
    padding: 10px 0;
    border-bottom: 1px solid #ececeb;
  }

  .gridos-logo {
    height: 100%;
    width: 100%;
  }

  .contents-container {
    flex-grow: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .workspace-text {
    margin-top: 5px;
    word-wrap: break-word;
    word-break: break-word;
    text-align: center;
  }

  .login-button {
    margin-top: 20px;
    width: 180px;
  }

  .spinner {
    width: 180px;
    height: 76px;
  }
}
