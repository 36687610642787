.service-dashboard {
  .content {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-wrap: wrap;
    margin: 0;
    padding: 10px;
  }
}
