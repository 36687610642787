.create-program {
  .content {
    flex-grow: 1;
    margin: 0;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  .form-container {
    width: 400px;
  }

  .text-input {
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }

  .row {
    margin-top: 10px;
  }

  .objective-settings {
    margin-left: 15px;
  }

  .radio-btn {
    margin-left: 0;
  }
}
