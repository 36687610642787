@import 'styles/colors.scss';

.sub-table {
  .-header {
    display: none;
  }
}

.enrollment-container {
  // display: flex;
  // flex-direction: column;
  // width: 100%;
  // height: 100%;
  // align-items: center;
  // justify-content: space-evenly;
  // overflow-y: auto;

  // .title-bar {
  //   height: 50px;
  //   width: 90%;
  //   display: flex;
  //   flex-direction: row;
  //   align-items: center;
  //   flex-shrink: 0;
  //   justify-content: space-between;
  //   .title-right {
  //     width: 45%;
  //     .search-container {
  //       width: 100%;
  //     }
  //   }
  // }

  // .title {
  //   padding: 0;
  //   margin: 0;
  // }

  .table-container {
    padding: 20px;

    .rt-thead .rt-resizable-header-content {
      text-align: left;
      font-size: 1.2em;
    }
    .rt-expander {
      height: 100%;
      width: 100%;
      margin: 0;
    }
  }
}

a.disabled-link {
  pointer-events: none;
  color: $medium-gray;
  text-decoration: none;
}
