@import 'styles/colors.scss';

.der {
  .column-container {
    width: 100%;
    height: 50%;
    padding: 20px;
    display: flex;
    flex-direction: row;

    .column {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      padding: 10px;
      max-width: 50%;
    }

    .row {
      margin-top: 10px;
      max-width: 350px;
    }
  }

  .field {
    &-label {
      width: 150px;
      margin-right: 10px;
      display: inline-block;
    }
  }

  .charts {
    width: 100%;
    height: 50%;
    padding: 20px;
  }
}
