.tooltip {
  font-family: Helvetica, Arial, sans-serif;
  position: absolute;
  padding: 4px;
  margin: 8px;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  max-width: 300px;
  font-size: 15px;
  font-weight: lighter;
  z-index: 9;
  pointer-events: none;

  .dotted-line {
    border-bottom: 1px dotted white;
    margin-bottom: 4px;
    width: 100%;
  }
}
