.mapping-card {
  padding: 10px;

  .button-menu {
    display: flex;
    align-items: center;

    .error.caption-text {
      margin-right: 10px;
    }
  }

  .secondary-title {
    color: #949899;
  }

  .mapping-row {
    align-items: flex-end;
    display: flex;
    justify-content: space-around;
    height: 75px;
    width: 100%;

    .number-input-group {
      display: flex;
      flex-grow: 1;
      height: 100%;
      margin: 0 10px 0 0;
      width: 100px;
    }

    .number-input {
      align-items: flex-start;
      flex-direction: column;
      flex-grow: 1;
      height: 100%;
      justify-content: space-between;
      margin: 0;
      &-container {
        width: 100%;
      }

      &__input {
        flex-grow: 1;
      }
    }

    .select-column {
      flex-grow: 1;
      height: 100%;
      margin: 0 10px 0 0;
      width: 50px;
    }

    .mapping-select {
      flex-grow: 0;
    }

    .current-value {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      height: 100%;
      justify-content: space-between;
      width: 50px;
      .value {
        display: flex;
        justify-content: space-between;
        line-height: 1.5;

        span {
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .unit {
          flex-shrink: 0;
          text-align: center;
          max-width: 50px;
          width: auto;
        }
      }
    }

    .tip-span {
      margin: 0 10px;
      margin-bottom: 3px;
    }
  }
}
