.iso-list {
  .content {
    flex-grow: 1;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .iso-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 10px;
    width: 90%;
  }

  .iso-link {
    color: unset;
    text-decoration: none;
  }
}
