.te {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;

  .container {
    display: flex;
    flex-direction: row;
    height: calc(100vh - 50px);
  }
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(~material-design-icons-iconfont/dist/fonts/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local('Material Icons'), local('MaterialIcons-Regular'),
    url(~material-design-icons-iconfont/dist/fonts/MaterialIcons-Regular.woff2)
      format('woff2'),
    url(~material-design-icons-iconfont/dist/fonts/MaterialIcons-Regular.woff)
      format('woff'),
    url(~material-design-icons-iconfont/dist/fonts/MaterialIcons-Regular.ttf)
      format('truetype');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}
