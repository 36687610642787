@import 'styles/colors.scss';
@import 'styles/font_sizes.scss';

.text-input-group {
  margin: 3px 0;
  .text-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0;
    &__input {
      background-color: transparent;
      border: 1px solid $light-gray;
      color: $dark-gray;
      width: 100%;
      border-radius: 4px;
      padding: 2px 6px;
      font-size: $body-text;
      height: 30px;
      &.text-invalid {
        border-color: $warning-red;
      }

      &:disabled {
        background-color: $light-gray;
        color: $dark-gray;
        cursor: not-allowed;
      }
    }
    &__edited {
      border-color: $pacific-blue;
    }
    &__label {
      font-size: $body-text;
      color: inherit;
      color: #909090;
      padding-bottom: 6px;
    }

    &-container {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    &-with-unit {
      width: 85px;
      border-radius: 4px 0 0 4px;
    }
  }

  .text-invalid {
    border: 1px solid $warning-red !important;
  }

  .input-error {
    font-size: $caption-text;
    color: $warning-red;
    text-align: right;
    width: 100%;
  }
}
