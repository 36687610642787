.asset-config-settings {
  width: 100%;
  padding-left: 8px;
  &-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
  }

  .save-button {
    min-width: 84px;
    height: 36px;
    border-radius: 7px;
    color: #909090;
  }

  .section {
    padding: 20px 6px;

    .row {
      width: fit-content;
      margin-bottom: 10px;
      /*check if needed .label*/
      .label {
        color: #909090;
        padding-bottom: 6px;
      }
    }

    .tip-span {
      width: fit-content;
    }

    .text-input {
      width: 300px;
      label {
        margin-right: 10px;
        color: black;
      }
    }

    .email-row {
      > * {
        display: inline-block;
      }
      .email-checkbox {
        top: 2px;
        margin-right: 10px;
      }
      span {
        display: none;
        font-weight: bold;
        font-size: 0.75em;
        &.not-enrolled {
          display: block;
        }
      }
      &::after,
      &::before {
        content: ' ';
        display: block;
        width: 100%;
        height: 1.5px;
        background-color: #ddd;
      }
      &::before {
        margin-top: 20px;
        margin-bottom: 10px;
      }
      &::after {
        margin-top: 7px;
      }
    }
  }
}
