.reports {
  .content {
    flex-grow: 1;
    margin: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 10px;
  }

  .card {
    width: 295px;
    height: 300px;
  }

  .card-content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .settlement-card .content {
    overflow: initial;
  }

  .card-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
    width: 100%;
    padding-bottom: 5px;

    &-label {
      width: 50px;
    }

    .date-picker__input {
      width: unset;
    }
  }

  .year-input {
    .number-input__label {
      width: 52px;
    }
    .number-input-container {
      flex-grow: 1;
      input {
        flex-grow: 1;
        width: unset;
      }
    }
  }
}
