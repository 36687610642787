.asset-settings {
  .settings-form {
    width: 100%;
    padding-left: 10px;
  }

  .close-button {
    border: none;
    background: none;

    color: inherit;
    &:visited {
      color: inherit;
    }
  }

  &-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 10px;

    .section {
      display: flex;
      flex-direction: column;
      padding-top: 10px;
    }

    .row {
      align-items: center;
      display: flex;
      margin-left: 10px;
    }
  }

  .column {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 10px;
  }

  .column-header {
    margin: 0;
    padding: 0;
  }

  .number-input {
    justify-content: flex-start;

    label {
      margin-right: 10px;
    }
  }

  .map-container {
    margin-top: 20px;
    width: 500px;
    height: 250px;
  }
}
