@import 'styles/colors.scss';

/**
 * Single Element CSS Spinners.
 * License Here: https://github.com/lukehaas/css-loaders/blob/step2/LICENSE
 */

/* Circles Loading Spinner Styles */
.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.loading-spinner {
  &--circles {
    font-size: 25px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    -webkit-animation: load5 1.5s infinite ease;
    animation: load5 1.5s infinite ease;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  @-webkit-keyframes load5 {
    0%,
    100% {
      box-shadow: 0em -2.6em 0em 0em $black, 1.8em -1.8em 0 0em $black-20,
        2.5em 0em 0 0em $black-20, 1.75em 1.75em 0 0em $black-20,
        0em 2.5em 0 0em $black-20, -1.8em 1.8em 0 0em $black-20,
        -2.6em 0em 0 0em $black-50, -1.8em -1.8em 0 0em $black-70;
    }
    12.5% {
      box-shadow: 0em -2.6em 0em 0em $black-70, 1.8em -1.8em 0 0em $black,
        2.5em 0em 0 0em $black-20, 1.75em 1.75em 0 0em $black-20,
        0em 2.5em 0 0em $black-20, -1.8em 1.8em 0 0em $black-20,
        -2.6em 0em 0 0em $black-20, -1.8em -1.8em 0 0em $black-50;
    }
    25% {
      box-shadow: 0em -2.6em 0em 0em $black-50, 1.8em -1.8em 0 0em $black-70,
        2.5em 0em 0 0em $black, 1.75em 1.75em 0 0em $black-20,
        0em 2.5em 0 0em $black-20, -1.8em 1.8em 0 0em $black-20,
        -2.6em 0em 0 0em $black-20, -1.8em -1.8em 0 0em $black-20;
    }
    37.5% {
      box-shadow: 0em -2.6em 0em 0em $black-20, 1.8em -1.8em 0 0em $black-50,
        2.5em 0em 0 0em $black-70, 1.75em 1.75em 0 0em $black,
        0em 2.5em 0 0em $black-20, -1.8em 1.8em 0 0em $black-20,
        -2.6em 0em 0 0em $black-20, -1.8em -1.8em 0 0em $black-20;
    }
    50% {
      box-shadow: 0em -2.6em 0em 0em $black-20, 1.8em -1.8em 0 0em $black-20,
        2.5em 0em 0 0em $black-50, 1.75em 1.75em 0 0em $black-70,
        0em 2.5em 0 0em $black, -1.8em 1.8em 0 0em $black-20,
        -2.6em 0em 0 0em $black-20, -1.8em -1.8em 0 0em $black-20;
    }
    62.5% {
      box-shadow: 0em -2.6em 0em 0em $black-20, 1.8em -1.8em 0 0em $black-20,
        2.5em 0em 0 0em $black-20, 1.75em 1.75em 0 0em $black-50,
        0em 2.5em 0 0em $black-70, -1.8em 1.8em 0 0em $black,
        -2.6em 0em 0 0em $black-20, -1.8em -1.8em 0 0em $black-20;
    }
    75% {
      box-shadow: 0em -2.6em 0em 0em $black-20, 1.8em -1.8em 0 0em $black-20,
        2.5em 0em 0 0em $black-20, 1.75em 1.75em 0 0em $black-20,
        0em 2.5em 0 0em $black-50, -1.8em 1.8em 0 0em $black-70,
        -2.6em 0em 0 0em $black, -1.8em -1.8em 0 0em $black-20;
    }
    87.5% {
      box-shadow: 0em -2.6em 0em 0em $black-20, 1.8em -1.8em 0 0em $black-20,
        2.5em 0em 0 0em $black-20, 1.75em 1.75em 0 0em $black-20,
        0em 2.5em 0 0em $black-20, -1.8em 1.8em 0 0em $black-50,
        -2.6em 0em 0 0em $black-70, -1.8em -1.8em 0 0em $black;
    }
  }
  @keyframes load5 {
    0%,
    100% {
      box-shadow: 0em -2.6em 0em 0em $black, 1.8em -1.8em 0 0em $black-20,
        2.5em 0em 0 0em $black-20, 1.75em 1.75em 0 0em $black-20,
        0em 2.5em 0 0em $black-20, -1.8em 1.8em 0 0em $black-20,
        -2.6em 0em 0 0em $black-50, -1.8em -1.8em 0 0em $black-70;
    }
    12.5% {
      box-shadow: 0em -2.6em 0em 0em $black-70, 1.8em -1.8em 0 0em $black,
        2.5em 0em 0 0em $black-20, 1.75em 1.75em 0 0em $black-20,
        0em 2.5em 0 0em $black-20, -1.8em 1.8em 0 0em $black-20,
        -2.6em 0em 0 0em $black-20, -1.8em -1.8em 0 0em $black-50;
    }
    25% {
      box-shadow: 0em -2.6em 0em 0em $black-50, 1.8em -1.8em 0 0em $black-70,
        2.5em 0em 0 0em $black, 1.75em 1.75em 0 0em $black-20,
        0em 2.5em 0 0em $black-20, -1.8em 1.8em 0 0em $black-20,
        -2.6em 0em 0 0em $black-20, -1.8em -1.8em 0 0em $black-20;
    }
    37.5% {
      box-shadow: 0em -2.6em 0em 0em $black-20, 1.8em -1.8em 0 0em $black-50,
        2.5em 0em 0 0em $black-70, 1.75em 1.75em 0 0em $black,
        0em 2.5em 0 0em $black-20, -1.8em 1.8em 0 0em $black-20,
        -2.6em 0em 0 0em $black-20, -1.8em -1.8em 0 0em $black-20;
    }
    50% {
      box-shadow: 0em -2.6em 0em 0em $black-20, 1.8em -1.8em 0 0em $black-20,
        2.5em 0em 0 0em $black-50, 1.75em 1.75em 0 0em $black-70,
        0em 2.5em 0 0em $black, -1.8em 1.8em 0 0em $black-20,
        -2.6em 0em 0 0em $black-20, -1.8em -1.8em 0 0em $black-20;
    }
    62.5% {
      box-shadow: 0em -2.6em 0em 0em $black-20, 1.8em -1.8em 0 0em $black-20,
        2.5em 0em 0 0em $black-20, 1.75em 1.75em 0 0em $black-50,
        0em 2.5em 0 0em $black-70, -1.8em 1.8em 0 0em $black,
        -2.6em 0em 0 0em $black-20, -1.8em -1.8em 0 0em $black-20;
    }
    75% {
      box-shadow: 0em -2.6em 0em 0em $black-20, 1.8em -1.8em 0 0em $black-20,
        2.5em 0em 0 0em $black-20, 1.75em 1.75em 0 0em $black-20,
        0em 2.5em 0 0em $black-50, -1.8em 1.8em 0 0em $black-70,
        -2.6em 0em 0 0em $black, -1.8em -1.8em 0 0em $black-20;
    }
    87.5% {
      box-shadow: 0em -2.6em 0em 0em $black-20, 1.8em -1.8em 0 0em $black-20,
        2.5em 0em 0 0em $black-20, 1.75em 1.75em 0 0em $black-20,
        0em 2.5em 0 0em $black-20, -1.8em 1.8em 0 0em $black-50,
        -2.6em 0em 0 0em $black-70, -1.8em -1.8em 0 0em $black;
    }
  }
}
