@import 'styles/colors.scss';
@import 'styles/font_sizes.scss';

.badge {
  background: #ececec;
  padding: 5px 0 4px;
  border-radius: 25px;
  display: inline-block;
  text-align: center;
  width: 125px;
}

.badge.success {
  background: $success;
  color: white;
}

.badge.error {
  background: $error;
  color: white;
}
