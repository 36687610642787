.measurement-feeds {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .content {
    padding: 10px;
  }

  .input-container {
    width: 100%;

    .input-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-top: 5px;
      padding-bottom: 5px;
    }

    .input-wrapper {
      width: 300px;
    }

    .date-wrapper {
      width: 300px;
      display: flex;
      align-items: center;
    }
  }

  .chart-container {
    width: 100%;
    flex-grow: 1;
  }
}
