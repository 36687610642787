.bids-offers {
  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .control-container {
    align-items: center;
    border-bottom: 1px solid darkgray;
    display: flex;
    justify-content: space-around;

    .wrapped-select {
      max-width: 300px;
    }

    .event-display-container {
      width: 400px;
    }
  }

  .chart-container {
    display: flex;
    height: 300px;
    margin-bottom: 20px;
    padding-left: 20px;
    width: calc(100% - 20px);
  }

  .table-container {
    padding-left: 20px;
    width: calc(100% - 20px);
  }

  .center-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .event-selector-container {
    align-items: center;
    display: flex;
  }

  .rt-resizable-header-content {
    text-align: left;
  }
}
