.home-route {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;

  .cta {
    font-size: 48px;
    color: #606060;
    font-weight: normal;
  }
}
