@import 'styles/colors.scss';
@import 'styles/font_sizes.scss';

.single-timeseries {
  .input-container {
    padding-left: 10px;
    width: 400px;
  }

  .text-input {
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }

  .rt-resizable-header-content {
    text-align: left;
  }

  .upload-button {
    margin-right: 10px;
  }

  .upload-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;

    .select-btn {
      width: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      border-radius: 4px;
      color: $white;
      cursor: pointer;
      font-size: $body-text;
      height: 36px;
      overflow: hidden;
      padding: 0 12px;
      background-color: $navy-blue;
      &:hover {
        background-color: $dark-blue;
      }
    }

    .footer {
      display: flex;
      flex-direction: row;
      width: 200px;
      justify-content: space-between;
    }

    .filename {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
