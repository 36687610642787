.manual-events {
  .content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .event-card {
    height: 350px;
    width: 350px;
  }

  .date-label {
    padding-top: 10px;
  }

  .settlement-range {
    width: 100%;
  }

  .rotating-icon {
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}
