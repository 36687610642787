$success: #6d982a;
$error: #ca001a;

$white: #fff;
$white-20: rgba(255, 255, 255, 0.2);
$white-50: rgba(255, 255, 255, 0.5);
$white-70: rgba(255, 255, 255, 0.7);

$off-white: #f7f7f7;

$light-blue: #a3c7eb;
$pacific-blue: #009ddc;
$navy-blue: #0079c1;
$dark-blue: #5a9cde;
$blue-gray: #8894af;

$light-green: #c1f5c2;
$dark-green: #008000;

$light-red: #ffcccc;
$salmon: #fc7169;

$very-light-gray: #f1f2f2;
$light-gray: #d4d4d4;
$medium-gray: #949899;
$dark-gray: #606060;
$darker-gray: #404040;
$transparent-gray: rgba(209, 209, 209, 0.3);

$medium-charcoal: #383838;
$dark-charcoal: #262626;

$black: #000;
$black-20: rgba(0, 0, 0, 0.2);
$black-50: rgba(0, 0, 0, 0.5);
$black-70: rgba(0, 0, 0, 0.7);

$warning-red: #ff3a00;

// Text Colors
$text-main: $dark-charcoal;
$text-disabled: #cccccc;
