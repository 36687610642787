@import 'styles/colors.scss';
@import 'styles/font_sizes.scss';

.load-contribution {
  display: flex;
  flex-direction: column;
  width: 100%;

  &-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .market-selector {
      display: flex;
      flex-direction: row;
      margin: 20px;
    }

    .date-picker {
      display: flex;
      flex-direction: row;
    }

    .right {
      display: flex;
      flex-direction: row;
      margin: 20px;
    }
  }

  .upload-form {
    display: flex;
    flex-direction: column;
    height: 100px;
    width: 100%;
    justify-content: space-between;
    padding: 0px;

    .select-btn {
      width: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      border-radius: 4px;
      color: $white;
      cursor: pointer;
      font-size: $body-text;
      height: 36px;
      overflow: hidden;
      padding: 0 12px;
      background-color: $navy-blue;
      &:hover {
        background-color: $dark-blue;
      }
    }

    .footer {
      display: flex;
      flex-direction: row;
      width: 200px;
      justify-content: space-between;
    }
  }

  .charts {
    height: 400px;
    padding: 20px;
  }

  .wrapped-select {
    padding-bottom: 10px;
    width: 250px;
  }

  .rotating-icon {
    animation: spin 2s linear infinite;
  }
}
