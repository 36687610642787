@import 'styles/colors.scss';

.themed-tabs {
  display: flex;
  flex-direction: column;
  width: 100%;

  .tab-container {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .react-tabs__tab-panel {
    min-height: 0;
    &--selected {
      display: flex;
      flex: 1;
      height: 100%;
      min-height: 0;
      color: #262626;
    }
  }

  .react-tabs__tab-list {
    margin-top: 0;
    padding-left: 0;
    margin-bottom: 5px;
    list-style: none;
    display: flex;
    flex-shrink: 0;
    height: 48px;
    min-height: 0;
    border-bottom: 1px solid transparent;
  }

  .react-tabs__tab {
    min-width: 90px;
    max-width: fit-content;
    min-height: 48px;
    flex-grow: 1;
    text-align: center;
    cursor: pointer;
    padding: 10px 16px 12px 16px;
    color: #262626;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid transparent;
    font-weight: 400;
    white-space: nowrap;
    &:first-of-type {
      padding-left: 21px;
    }

    &--selected,
    &:focus {
      color: $pacific-blue;
      border: 3px $pacific-blue;
      background: transparent;
      padding: 10px 16px;
      outline: none;
      box-shadow: none;
      margin-top: -2px;
      flex-shrink: 0;

      div {
        margin-top: 8px;
        &::after {
          content: ' ';
          display: block;
          position: relative;
          border-style: none none solid none;
          outline: none;
          background: none;
          width: 100%;
          padding-top: 5px;
        }
      }
    }
    &:hover {
      background-color: #f5f5f5;
    }
    &--disabled {
      cursor: not-allowed;
      &:hover {
        .permission-disabled-tip .tooltip .tooltip-contents {
          visibility: visible;
        }
      }
    }

    .tip-span {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
    }
  }

  .react-tabs__tab--selected {
    font-weight: 500;
  }
  &.shadow {
    .react-tabs__tab-list {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15),
        0 19px 38px 0 rgba(0, 0, 0, 0.05);
    }
  }
  &.line {
    .react-tabs__tab-list {
      box-shadow: none;
      border-bottom: 1px solid #ececec;
    }
  }
}
