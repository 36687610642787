@import 'styles/colors.scss';
@import 'styles/font_sizes.scss';

.radio-btn-group {
  display: flex;
  width: 100%;

  .label {
    font-size: $body-text;
  }

  &--column {
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    .option-group {
      margin: 3px 0;
    }
  }

  &--row {
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
  }

  .option-group {
    display: flex;
    flex-grow: 1;
  }

  .option-icon-group {
    display: flex;
    align-items: center;
    user-select: none;
  }

  .option-label-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    color: $text-main;

    .option-label-body {
      margin-left: 40px;
    }
  }

  .radio-btn {
    display: flex;
    border: 2px solid $medium-gray;
    border-radius: 18px;
    flex-shrink: 0;
    margin: 0 10px;
    height: 18px;
    width: 18px;

    &--selected {
      border-color: $pacific-blue;
      .radio-btn-fill {
        background-color: $pacific-blue;
      }
    }
  }

  .radio-btn-fill {
    border-radius: 15px;
    flex-grow: 1;
    margin: 2px;
  }

  .radio-btn-input {
    display: hidden;
    height: 0px;
    width: 0px;
    overflow: hidden;
    opacity: 0;

    &:focus + .option-label-container label .radio-btn {
      box-shadow: 0px 0px 1px 10px rgba(0, 157, 220, 0.1);
    }

    &:disabled + .option-label-container {
      color: $text-disabled;
      .radio-btn {
        border-color: $text-disabled;
        &--selected .radio-btn-fill {
          background-color: $text-disabled;
        }
      }
    }
  }
}
