.add-iso {
  .title-right {
    display: flex;
  }

  .content {
    flex-grow: 1;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .cancel-button {
    text-decoration: none;
    color: unset;
    margin-right: 20px;
  }

  .iso-content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
  }

  .input-container {
    width: 400px;
    display: flex;
    align-items: center;

    .text-input-group {
      width: 100%;
    }

    .text-input__label {
      width: 200px;
    }
  }
}
