.program-settings {
  .themed-tabs {
    height: 100%;
  }

  .column {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 10px;
    max-width: 350px;

    .row {
      margin-top: 10px;
    }

    .objective-settings {
      margin-left: 15px;
    }
  }

  .text-input {
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }

  .number-input {
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;

    &-container {
      width: 100%;

      input {
        flex-grow: 1;
      }
    }
  }

  .radio-btn {
    margin-left: 0;
  }
}
