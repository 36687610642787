.zoom-controls {
  position: absolute;
  z-index: 1;
}
.zoom-controls {
  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 5px;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.35);
    cursor: pointer;
    height: 40px;
    margin-top: 2px;
    overflow: hidden;
    padding: 0;
    width: 40px;
    z-index: 1;
    background-color: #ffffff;
    color: #262626;
    &:hover {
      background-color: #ffffff !important;
    }

    &:disabled {
      cursor: not-allowed;
      color: grey;
    }
  }
  &-top {
    border-radius: 5px 5px 0 0;
  }
  &-bottom {
    border-radius: 0 0 5px 5px;
  }

  .button-pair {
    margin: 10px 0;
    display: flex;
    flex-direction: column;
  }
}
