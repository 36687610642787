.legend {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.35);
  width: 250px;

  .legend-item {
    display: flex;
    margin-bottom: 5px;
  }

  .color {
    width: 20px;
    display: block;
    height: 20px;
    border-radius: 5px;
    margin-right: 10px;
  }

  &-header {
    display: flex;
  }
}
