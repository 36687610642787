.weather {
  .weather-station-list {
    display: flex;
    flex-wrap: wrap;
  }

  .create-station-text-input {
    .text-input {
      align-items: flex-start;
      flex-direction: column;
      justify-content: flex-start;
    }
  }
}
