@import 'styles/colors.scss';
@import 'styles/font_sizes.scss';

.card {
  border-radius: 4px;
  padding: 0;
  margin: 10px;
  background-color: $white;
  border: 1px solid #ececec;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  color: #262626;

  display: flex;
  flex-direction: column;

  .border {
    padding: 10px 15px;
  }

  header {
    display: flex;
    align-items: center;

    .card-menu {
      flex-grow: 1;

      .title-menu {
        color: $dark-gray;

        .title {
          font-size: $body-text;
          margin: 2px 0;
        }
        .secondary-title {
          font-size: $caption-text;
          font-weight: normal;
          margin: 2px 0;
        }
      }
    }
    .button-menu {
      flex-grow: 0;
    }
  }
  .content {
    padding: 10px 15px;
    font-size: $body-text;
    border-top: 1px solid #ececec;
  }

  .content.no-border {
    border-top: none;
  }

  .footer {
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
  }
}
