.iso-charts {
  width: 100%;

  .input-control-container {
    display: flex;
    align-items: center;
  }

  .single-chart {
    height: 300px;
  }

  .limited-width {
    width: 400px;
  }
}
