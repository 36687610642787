@import 'styles/colors.scss';

.header-layout {
  display: flex;
  flex-direction: column;
  width: 100%;

  .title-bar {
    align-items: center;
    border-bottom: 1px solid darkgray;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    height: 50px;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    a {
      text-decoration: none;
      .title {
        color: $pacific-blue;
      }
    }
    h2,
    p {
      font-size: 0.8em;
      font-weight: normal;
    }
  }

  .title {
    margin: 0;
    padding: 0;
    color: #606060;
  }

  .title-right-container {
    align-items: center;
    display: flex;
  }

  .content {
    height: calc(100% - 50px);
    overflow-y: auto;
  }
}
