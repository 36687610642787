.feeder-config-settings {
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;

  &-header {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .section {
    width: 300px;
  }
}
