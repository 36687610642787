@import 'styles/colors.scss';

.analysis-failed-overlay {
  background-color: $white;
  border: 1px solid #ececec;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  display: flex;
  flex-direction: row;
  height: 40px;
  left: calc(50% - 132.5px);
  position: absolute;
  top: 25px;
  width: 265px;

  .icon-container {
    align-items: center;
    background-color: $error;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    color: $white;
    display: flex;
    flex-direction: row;
    height: 40px;
    justify-content: center;
    width: 40px;
  }

  .text-container {
    align-items: center;
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
  }
}
