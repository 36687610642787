@import 'styles/colors.scss';

.ders {
  .content {
    flex-grow: 1;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .list-container {
    width: 100%;
    height: 100%;
    overflow: scroll;
    padding: 20px;
  }

  .der-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .der-item {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
