.timeseries {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &-list {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 400px;

    &-item {
      height: 50px;
      display: flex;
      align-items: center;

      background: white;
      border-radius: 4px;
      box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.15); // black @ 15%
      padding: 20px;
      margin-bottom: 10px;

      .name {
        flex-grow: 1;
      }
    }
  }
}
