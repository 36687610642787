.create-event {
  .content {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }

  .form-container {
    width: 50%;
  }

  .form-row {
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
  }
}
