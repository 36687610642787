.load-forecast {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .content {
    padding: 10px;
  }

  .control-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100%;
  }

  .control-wrapper {
    width: 300px;
  }

  .date-wrapper {
    width: 300px;
    display: flex;
    align-items: center;
  }

  .chart-wrapper {
    height: calc(100% - 48px);
  }
}
