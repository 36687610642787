@import 'styles/colors.scss';

.service-status-tile {
  align-items: center;
  height: 200px;
  width: 300px;

  header {
    height: 48px;
  }

  .title {
    align-items: center;
    display: flex;
    flex-direction: row;
  }

  .content {
    display: flex;
    flex-direction: column !important;
    justify-content: flex-start;
    width: 100%;
  }

  &-row {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    &:nth-child(odd) {
      background-color: $light-gray;
    }
  }

  .icon-success {
    color: $success;
  }

  .icon-error {
    color: $error;
  }
}
