.program-admin-settings {
  .content {
    display: flex;
    flex-direction: column;
    padding: 10px;

    .row {
      width: 300px;
    }
  }
}
