@import 'styles/colors.scss';
@import 'styles/font_sizes.scss';

.settings {
  height: 100%;
  position: relative;
  width: 50px;

  .tip-span {
    height: 100%;
    width: 100%;
  }

  .icon-container {
    color: $white;
  }

  &-container {
    border-radius: 3px;
    box-shadow: 0 12px 17px 0 rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    max-height: 0px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    right: 0;
    top: 37px;
    transition: 0.3s ease max-height, 0.2s ease padding;
    width: 200px;
    z-index: 2000;
    &--active {
      max-height: 400px;
    }
  }

  &-tile {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    font-size: $body-text;
    justify-content: center;
    height: 35px;
    padding-left: 24px;
    padding-right: 24px;
    &--help {
      padding-top: 7px;
    }
  }

  &-tile-title {
    font-size: $body-text;
    margin: 0;
    padding: 0;
  }

  &-btn-container {
    width: 100%;
    padding: 0px 24px 0px 24px;
    height: 48px;
    &:after {
      content: '';
      display: block;
      position: relative;
      top: 0;
      left: 2.5%;
      height: 1px;
      width: 95%;
    }
  }

  &-link {
    border: none;
    width: 100%;
    cursor: pointer;
    text-decoration: none;
  }

  &-logout__btn {
    border: none;
    width: 100%;
    height: 100%;
    cursor: pointer;
    &:hover {
      color: $white;
    }

    .logout-icon {
      padding-right: 15px;
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    font-size: $body-text;
    height: 100%;
    &.icon-btn {
      height: 100%;
      width: 100%;
      &:hover {
        background-color: transparent !important;
      }
    }

    &:hover,
    &--active,
    &:focus {
      outline: none;
    }
  }

  &-version-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 3px 23px 7px;
    width: 100%;
    flex-shrink: 0;
  }

  &-logo {
    height: 25px;
    flex-grow: 0;
    flex-shrink: 0;
    margin: 8px 0;
  }

  &-version {
    // color: $light-gray;
    font-size: $caption-text;
    word-break: break-all;
    text-align: center;
    width: 100%;
  }

  &-container {
    background-color: #fafafa;
    box-shadow: 0 12px 17px 0 rgba(0, 0, 0, 0.5);
    color: $dark-gray;
  }

  &-btn-container:after {
    background: #fafafa;
  }

  &-logout__btn,
  &-help-link {
    background-color: transparent;
    color: $dark-gray;
    padding: 0px;
    text-align: left;
  }

  &-separator {
    height: 1px;
    width: 100%;
    background: $dark-gray;
  }

  &-tile {
    background-color: transparent;
    &:hover {
      background-color: rgba(0, 121, 193, 0.1);
      .settings-help-link,
      .settings-logout__btn,
      .settings-tour__btn {
        color: $medium-charcoal;
      }
    }
    a {
      color: $dark-gray;
      text-decoration: none;
    }
    .material-icons,
    .icon-text {
      vertical-align: middle;
      margin: 0px 5px;
    }
  }
}
