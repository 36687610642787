@import '~flatpickr/dist/flatpickr.min.css';
@import 'styles/colors.scss';
@import 'styles/font_sizes.scss';

.date-picker {
  align-items: center;
  display: flex;
  height: 36px;

  &__btn {
    align-items: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    font-size: $icon-size;
    height: 100%;
    padding: 0;
    color: $medium-gray;
    &:hover,
    &:focus {
      outline: none;
      color: $pacific-blue;
    }
  }

  &__input {
    border-radius: 3px;
    width: 180px;
    height: 30px;
    padding: 0 5px;
    background-color: $white;
    border: 1px solid $light-gray;
    color: $dark-charcoal;
    font-size: 1em;
  }
}

.reached-limit {
  cursor: not-allowed;
  &:hover,
  &:focus {
    outline: none;
    color: $medium-gray;
  }
}

.DatePicker-calendar-instance {
  border: 1px solid $very-light-gray;
  border-radius: 0;
  box-shadow: 0 3px 13px rgba(0, 0, 0, 0.18);

  .flatpickr-months {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;

    .flatpickr-prev-month,
    .flatpickr-next-month {
      height: 14px;
      padding: 0;
      position: relative;
      width: 40px;
    }
  }

  .flatpickr-current-month {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    font-size: $body-text;
    padding: 0;

    .cur-month,
    .cur-year {
      display: flex;
      align-items: center;
      font-weight: 400;
      height: 20px;
    }

    .cur-month {
      padding-top: 1px;
      font-size: $body-text;
    }

    .numInputWrapper {
      height: 19px;
    }
  }

  .flatpickr-weekday {
    font-weight: 400;
  }

  &.arrowTop:before,
  &.arrowTop:after,
  &.arrowBottom:before,
  &.arrowBottom:after {
    display: none;
  }

  .today {
    color: $pacific-blue;
    border-color: transparent;
    &:hover,
    &.selected {
      color: $white;
    }
  }

  .flatpickr-day:focus,
  .selected:focus {
    background-color: $pacific-blue;
    border-color: $pacific-blue;
    color: white;
  }
}
