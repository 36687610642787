@import 'styles/colors.scss';
@import 'styles/font_sizes.scss';

.weather-station {
  .control-container {
    align-items: center;
    border-bottom: 1px solid darkgray;
    display: flex;
    height: 50px;
    padding-left: 20px;
    width: 100%;

    .select-container {
      margin-left: 10px;
      margin-right: 10px;
      width: 250px;
    }
  }

  .chart-wrapper {
    height: calc(100% - 50px);
  }

  .upload-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;

    .select-btn {
      width: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      border-radius: 4px;
      color: $white;
      cursor: pointer;
      font-size: $body-text;
      height: 36px;
      overflow: hidden;
      padding: 0 12px;
      background-color: $navy-blue;
      &:hover {
        background-color: $dark-blue;
      }
    }

    .wrapped-select {
      padding-bottom: 10px;
      width: 250px;
    }

    .footer {
      display: flex;
      flex-direction: row;
      width: 200px;
      justify-content: space-between;
    }

    .filename {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
