@import 'styles/colors.scss';
@import 'styles/font_sizes.scss';

.search-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  .search-input-wrapper {
    position: relative;
    width: 100%;
    &::after {
      color: $medium-gray;
      font-family: 'Material Icons';
      font-size: $title-text;
      content: '\search';
      position: absolute;
      right: 0;
      top: 7px;
      width: 30px;
    }
  }

  .search-input {
    border: none;
    border-radius: 35px;
    font-family: 'Roboto', sans-serif;
    font-size: $body-text;
    height: 35px;
    outline: none;
    padding: 5px 36px 7px 15px;
    width: 100%;
    background-color: #fefefe;
    border-bottom: 1px solid $light-gray;

    &::-ms-clear {
      display: none;
    }

    &:focus {
      border-color: $pacific-blue;
      box-shadow: 0 0 7px $pacific-blue;
    }
  }
}
