@import 'styles/colors.scss';
@import 'styles/font_sizes.scss';

.header {
  height: 50px;
  background: linear-gradient(180deg, #0079c1, #016193);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;

  &-left {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .logo {
    height: 30px;
  }

  .program-select {
    margin-left: 30px;
    width: 250px;
  }

  .right-container {
    display: flex;
    align-items: center;
  }

  .username {
    align-items: center;
    border: none;
    border-radius: 30px;
    cursor: default;
    display: flex;
    height: 24px;
    margin: 0 10px 0 10px;
    overflow: hidden;
    padding: 0 10px 0 0;
    background-color: $transparent-gray;
    color: $white;

    &-name {
      font-size: $body-text;
      margin-left: 5px;
    }

    .user-icon {
      font-size: $caption-text;
      top: -1px;
      &-person {
        font-size: $title-text;
        bottom: -2px;
      }
    }
  }

  .settings {
    width: unset;
  }
}
