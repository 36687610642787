@import 'styles/colors.scss';
@import 'styles/font_sizes.scss';

.icon-btn-container {
  .icon-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 24px;
    width: 24px;
    padding: 0;
    background-color: transparent;

    &--active {
      background-color: #d4d4d4;
    }

    &.round {
      border-radius: 50%;
    }

    .icon-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 24px;
      width: 24px;
      font-size: $icon-size;
      .material-icons {
        font-size: inherit;
      }
    }

    &:disabled {
      cursor: not-allowed;

      .icon-btn-image {
        background-color: transparent;
      }
    }
  }

  .icon-container {
    color: #016193;
  }

  .custom-btn.icon-btn:hover {
    background-color: #f5f5f5;

    .icon-container {
      color: $light-gray;
    }
  }

  .custom-btn.icon-btn:disabled,
  .custom-btn.icon-btn:disabled:hover {
    background-color: transparent;
    .icon-container {
      color: #e2e2e2;
    }
  }
}
