@import 'styles/colors.scss';

.participant {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: scroll;

  .title-bar {
    height: 50px;
    width: 100%;
    border-bottom: 1px solid darkgray;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    flex-shrink: 0;
  }

  .title {
    padding: 0;
    margin: 0;
  }

  .content {
    flex-grow: 1;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .column-container {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
  }

  .column {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 10px;
  }

  .column:first-of-type {
    overflow-x: hidden;
    .scrollable-column {
      height: 100%;
      overflow-y: scroll;
      padding-top: 1px;
      padding-right: 6em; /* These 2 lines hide scroller of the first panel~ */
      margin-right: -6em; /* ~that contains Company and Invoicee info. */
    }
  }

  .row {
  }

  .flex-column-header-wrapper {
    display: flex;
    flex-direction: row;

    h3 {
      padding-right: 15px;
      padding-bottom: 2px;
    }
  }

  .column-header {
    margin: 0;
    padding: 0;
    scroll-snap-align: start;
  }

  .text-input {
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }

  .spinner-container {
    position: absolute;
    left: calc(50% - 30px);
    top: 50%;
  }

  .customer-asset-list {
    list-style: none;
    margin: 0;
    margin-top: 20px;
    padding: 0;

    &-item {
      padding-top: 5px;
      padding-bottom: 5px;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      justify-content: space-between;
    }
  }

  .add-der-section {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
