.asset-lmpd-settings {
  margin-top: 10px;
  width: 100%;

  &-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
}
