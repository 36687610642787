.single-event {
  .content {
    flex-grow: 1;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    width: 100%;
  }

  .themed-tabs {
    height: 100%;
  }

  .ReactTable {
    width: 100%;
  }

  .column {
    width: 50%;
    padding-left: 10px;
    padding-top: 10px;

    .history-list > .event-state {
      margin-left: 18px;
    }

    label {
      width: 200px;
    }

    .number-input-group > .number-input > .number-input-container {
      width: 100%;
    }
  }
}
