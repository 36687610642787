@import 'styles/colors.scss';
@import 'styles/font_sizes.scss';

.iso-list-item {
  display: flex;
  flex-direction: row;
  width: 100%;

  background: white;
  columns: $dark-gray;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 20px;
  margin-bottom: 10px;
  height: 70px;

  .col {
    width: 25%;
    display: flex;
    align-items: center;
    flex-grow: 1;

    &.status {
      font-size: $body-text;

      .badge {
        font-size: $caption-text;
        margin-right: 15px;
        padding: 10px;
        width: 90px;
      }
    }
  }
}
