@import 'styles/colors.scss';
@import 'styles/font_sizes.scss';

.custom-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 4px;
  color: $white;
  cursor: pointer;
  font-size: $body-text;
  height: 36px;
  overflow: hidden;
  padding: 0 12px;

  .btn-loading {
    margin-left: 10px;
  }

  &.primary-btn {
    background-color: $navy-blue;
    &:hover {
      background-color: $dark-blue;
    }
  }

  &.primary-btn {
    &:disabled,
    &:disabled:hover,
    &:disabled:focus {
      border: none;
      cursor: default;
      background-color: #e3e3e3;
      color: #aaaaaa;
    }
  }

  &.circular-type {
    padding: 0.5em;
    border-radius: 50%;
  }
}
