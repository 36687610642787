@import 'styles/colors.scss';

.participants {
  .icon-container {
    color: $dark-gray;
  }

  .content {
    flex-grow: 1;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .table-container {
    width: 100%;
    height: 100%;
    overflow: scroll;
    padding: 20px;
  }

  .center-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .icon-container {
    color: $dark-gray;
  }

  .rt-resizable-header-content {
    text-align: left;
  }

  .header-button {
    padding-left: 5px;
    padding-right: 5px;
  }
}
