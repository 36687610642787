@import 'styles/colors.scss';
@import 'styles/font_sizes.scss';

.wrapped-select {
  width: 100%;
  display: flex;
  flex-direction: column;

  .label {
    font-size: $body-text;
    color: #909090;
    padding-bottom: 6px;
  }

  &--row {
    flex-direction: row;
    align-items: center;

    .label {
      font-size: $body-text;
      color: inherit !important;
      padding-bottom: inherit !important;
    }

    .base-select {
      padding-left: 10px;
      flex-grow: 1;
    }
  }
}
