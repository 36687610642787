@import 'styles/colors.scss';

.events {
  .content {
    flex-grow: 1;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .table-container {
    width: 100%;
    height: 100%;
    overflow: scroll;
    padding: 20px;

    .header-row {
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 20px;
      border-bottom: 1px solid $dark-gray;
    }

    .icon-container {
      color: $dark-gray;
    }

    .select-filters {
      display: flex;
      justify-content: space-between;
      width: 525px;
      flex-direction: row;
    }

    .market-container {
      width: 200px;
    }

    .feeder-container {
      width: 300px;
    }

    .date-container {
      padding-right: 350px;
    }

    .right-buttons {
      display: flex;
    }
  }

  .center-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .warning-icon {
    color: $error;
  }

  .cancel-button {
    border: none;
    background: none;
    padding: 1px 7px;
  }

  .status {
    border-radius: 15px;
    display: flex;
    background-color: transparent;
    border-radius: 5px;
    cursor: default;
    padding: 5px;
    justify-content: center;
    margin-left: 10px;
    margin-right: 10px;
  }

  .rt-th {
    .flatpickr-input {
      text-align: center;
      width: 100%;
    }
  }
}

.ReactTable {
  .-pagination .-btn {
    background-color: $navy-blue;
    color: $white;
  }
  .-pagination .-btn:not([disabled]):hover {
    background-color: $dark-blue;
  }

  rt-th rt-resizable-header -cursor-pointer {
    height: 30px;
  }

  .rt-resizable-header-content {
    text-align: left;
  }
  .-sort-desc {
    box-shadow: none !important;
    &:before {
      content: '▼';
      color: $navy-blue;
      font-size: 14px;
      float: right;
    }
  }

  .-sort-asc {
    box-shadow: none !important;
    &:before {
      content: '▲';
      color: $navy-blue;
      font-size: 14px;
      float: right;
    }
  }
}
