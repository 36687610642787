.weather-station-card {
  height: 250px;
  width: 250px;

  .content {
    display: flex;
    flex-direction: column;
  }

  .text-input {
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
}
