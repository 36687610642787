.breadcrumbs {
  &-container {
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
  }
  &-separator {
    font-size: 2em;
    margin: 0 3px;
    font-weight: 100;
  }
}
