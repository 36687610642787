@import 'styles/colors.scss';

// Some best-practice CSS that's useful for most apps
// Just remove them if they're not what you want
html {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

button,
input,
optgroup,
select,
textarea {
  font-family: 'Roboto', sans-serif;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  color: #606060;
}

p {
  margin: 0;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.root {
  width: 100%;
  height: 100%;
}

// Remove chrome styles
textarea:focus,
input:focus,
button:focus {
  outline: none;
}

a,
a:link,
a:visited {
  color: $pacific-blue;
}
