@import 'styles/colors.scss';

.simulation-debug {
  .select-container {
    width: 300px;
  }

  .center-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .icon-success {
    color: $success;
  }

  .icon-error {
    color: $error;
  }

  .rt-resizable-header-content {
    text-align: left;
  }
}
