.measurement-mapping {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .content {
    padding: 10px;
  }

  .header-button {
    margin-left: 5px;
    margin-right: 5px;
  }

  .input-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .input-wrapper {
    width: 300px;
  }

  .modal-body {
    overflow: visible;
  }
}
