.operational-view {
  width: 100%;
  display: flex;
  flex-direction: column;

  .title-bar {
    height: 50px;
    width: 100%;
    border-bottom: 1px solid darkgray;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .scenario-display {
    font-weight: 500;
    margin: 0px 20px;
  }

  .map-container {
    width: 100%;
    flex-grow: 1;
  }

  .real-time {
    border-right: 1px solid darkgray;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    height: 100%;
  }

  .display-label {
    align-items: center;
    display: flex;
    font-weight: 600;
    height: 100%;
  }

  .map-button {
    border-radius: 0;
  }

  .market-select {
    margin-left: 10px;
    width: 200px;
  }

  .rail-container {
    display: flex;
    width: 100%;
    height: 100%;
  }

  .loading-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .der-select-container {
    align-items: center;
    display: flex;
  }
}
