.der-price-chart {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .price-chart-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
  }
}
