@import 'styles/colors.scss';

$navigation-side-bar-width: 180px;

.navigation {
  width: 51px;
  transition: 0.5s ease-in-out;
  overflow: hidden;
  height: 100%;
  border-right: 1px solid darkgray;
  padding-right: 1px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;

  &-scroll-container {
    height: calc(100% - 40px);
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
  }

  a,
  a:link,
  a:visited {
    color: #016193;
    text-decoration: none;
    cursor: pointer;
  }

  .nav-button {
    height: 49px;
    width: $navigation-side-bar-width;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 10px;
    color: #016193;
    fill: #016193;

    &-label {
      transition: opacity 0.5s ease-in-out;
      opacity: 0;
      margin-left: 15px;
    }

    &:hover {
      background-color: $light-gray;
    }

    &--selected {
      background-color: $light-gray;
    }

    &--disabled,
    &--disabled:hover {
      color: $light-gray;
      fill: $light-gray;
      background-color: $white;
    }
  }

  .nav-section-title {
    color: #016193;
    display: flex;
    padding-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    white-space: nowrap;
  }

  &--expanded {
    width: $navigation-side-bar-width;
    .nav-button-label {
      opacity: 1;
    }
  }

  .expand-button {
    &-wrapper {
      position: absolute;
      bottom: 5px;
      left: 5px;
    }
    background-color: transparent;
    color: #016193;
    align-self: flex-end;
    &:hover {
      background-color: transparent;
    }
  }

  .custom-icon {
    width: 24px;
    height: 24px;
  }

  .separator {
    height: 1px;
    width: 100%;
    background-color: darkgray;
  }
}

.side-bar-tooltip {
  left: 55px !important;
}
