.single-tag {
  .content {
    padding-left: 10px;
    width: 400px;
  }

  .text-input {
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }

  .number-input {
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;

    &-container {
      width: 100%;

      input {
        flex-grow: 1;
      }
    }
  }
}
