@import 'styles/colors.scss';
@import 'styles/font_sizes.scss';

.iso {
  width: 100%;
  display: flex;
  flex-direction: column;

  .themed-tabs {
    height: 100%;
  }

  .title-bar {
    height: 50px;
    width: 100%;
    border-bottom: 1px solid darkgray;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    flex-shrink: 0;
    justify-content: space-between;
    a {
      text-decoration: none;
      .title {
        color: $pacific-blue;
      }
    }
    h2,
    p {
      font-size: 0.8em;
      font-weight: normal;
    }
  }

  .title {
    padding: 0;
    margin: 0;

    &-right {
      display: flex;
    }
  }

  .delete-button {
    margin-right: 20px;
  }

  .content {
    flex-grow: 1;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    width: 100%;
  }

  .chart-container {
    width: 100%;
    display: flex;
    padding: 20px;
  }

  .iso-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
  }

  .inputs {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    .label {
      color: #909090;
      padding-bottom: 6px;
    }
  }

  .input-container {
    min-height: 60px;
    width: 400px;

    .text-input {
      align-items: flex-start;
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  .time-container {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 10px;

    .time-row {
      display: flex;
      justify-content: space-between;
      .wrapped-select {
        width: 150px;
      }
    }
  }

  .link-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 400px;

    .text-input-group {
      flex-grow: 1;
      margin-right: 20px;
      label {
        color: inherit !important;
        padding-bottom: inherit !important;
      }
    }
  }

  .select-btn {
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 4px;
    color: $white;
    cursor: pointer;
    font-size: $body-text;
    height: 36px;
    overflow: hidden;
    padding: 0 12px;
    background-color: $navy-blue;
    &:hover {
      background-color: $dark-blue;
    }
  }
}
