@import 'styles/colors.scss';
@import 'styles/font_sizes.scss';

.measurements {
  .content {
    align-content: flex-start;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-wrap: wrap;
    margin: 0;

    .measurements-link {
      align-items: center;
      display: flex;
      flex-direction: row;
      font-size: 36px;
      height: 125px;
      justify-content: center;
      margin-bottom: 10px;
      text-decoration: none;
      width: 400px;
    }
  }
}
