@import '~flatpickr/dist/flatpickr.min.css';
@import 'styles/colors.scss';
@import 'styles/font_sizes.scss';

.date-range-picker {
  align-items: center;
  display: flex;
  height: 36px;

  &__input {
    border-radius: 3px;
    width: 250px;
    height: 30px;
    padding: 0 5px;
    background-color: $white;
    border: 1px solid $light-gray;
    color: $dark-charcoal;
    font-size: 1em;
  }
}
